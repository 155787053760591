<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="faultRecords"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('faultTemplate.faultType', '故障分类')">
          <el-input v-model.trim="search.faultDesc" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('workOrder.faultTime', '故障时间')">
          <el-date-picker
            v-model="dateRange"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :unlink-panels="true"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            :default-time="['00:00:00', '23:59:59']"
            @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button :loading="exportLoading" class="greenBtn" @click="exportExcel">
          {{ $l("elevator.exportExcel", "导出") }}
        </el-button>
      </template>
      <el-table-column prop="gbFaultCode" label="国标/地标映射" width="120px"></el-table-column>
      <el-table-column prop="faultCode" label="原始故障码" width="120px"></el-table-column>
      <el-table-column prop="faultSn" label="故障编号" width="160px"></el-table-column>
      <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="140px" align="center"></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('elevator.name')"></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" width="140px" align="center"></el-table-column>
      <el-table-column prop="faultDesc" :label="$t('workOrder.faultType')">
        <template slot-scope="scope">
          <span v-if="scope.row.faultDesc === '000000'">{{ $t("workOrder.unKnowFault") }}</span>
          <span v-else>{{ scope.row.faultDesc }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="faultTime" :label="$t('workOrder.faultTime')" width="180px" align="center"></el-table-column>
      <el-table-column prop="faultRecoverTime" label="故障恢复时间" width="180px" align="center"></el-table-column>
      <el-table-column
        v-if="false"
        prop="sendTime"
        :label="$t('workOrder.mainBoardTime')"
        width="200px"></el-table-column>
      <el-table-column
        v-if="false"
        prop="faultFloor"
        :label="$t('workOrder.faultStorey')"
        width="100px"
        align="center"></el-table-column>
      <el-table-column
        prop="faultFloorCode"
        :label="$t('workOrder.faultFloorCode')"
        width="110px"
        align="center"></el-table-column>
      <el-table-column
        prop="faultFloor"
        :label="$t('workOrder.displayFaultFloor')"
        width="110px"
        align="center"></el-table-column>
    </vm-table>
  </div>
</template>
<script>

  import auth from "@/util/auth";

  export default {
    data() {
      return {
        showOrgId: auth.getUsername() === "admin" || auth.getUsername() === "superAdmin",
        exportLoading: false,
        loading: false,
        dateRange: [],
        search: {
          filter: "",
          faultDesc: "",
          startTime: "",
          endTime: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      exportExcel() {
        if (this.dateRange.length === 0) {
          this.$message.error(this.$l("common.faultTime", "请先填写故障时间（两个月之内）"));
          return;
        }
        let fullTime = [];
        for (let i = 0; i < this.dateRange.length; i++) {
          let fullYear = this.dateRange[i].split("-");
          let year = fullYear[0];
          let month = fullYear[1];
          let day = fullYear[2];

          let date = new Date(year, month, day);
          fullTime.push(date.getTime());
        }
        console.log((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000));
        if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) > 60) {
          this.$message.error(this.$l("common.sevenTime", "请填写两个月之内的时间！"));
          return;
        }
        let params = {
          startTime: this.dateRange != null ? this.dateRange[0] : "",
          endTime: this.dateRange != null ? this.dateRange[1] : "",
          filter: this.search.filter,
          faultDesc: this.search.faultDesc,
        };
        this.$api
          .exportFile("faultRecords/export", params, 60)
          .then(res => {
            this.exportLoading = false;
            let blob = new Blob([res.data]);
            let fileName = "";
            if (this.$i18n.isCn) {
              fileName = "故障记录.xlsx";
            } else {
              fileName = "FaultRecord Info.xlsx";
            }
            this.$message.success("成功");
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download = fileName;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            }
          })
          .catch(() => {
            this.exportLoading = false;
          });
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
      clearDateRange() {
        this.dateRange = [];
      },
    },
  };
</script>
<style lang="scss" scoped></style>
